import React, { useState } from "react";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Chip from "@mui/material/Chip";
//import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
//import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
//import ToggleButton from "@mui/material/ToggleButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
//import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { colours, TT } from "../consts.js";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
    BarChart,
    Bar,
    LabelList,
    //Label,
    Cell,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Tooltip,
    //Legend,
    ResponsiveContainer,
    ScatterChart,
    Scatter,
} from "recharts";
import InputLabel from "@mui/material/InputLabel";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import DatePicker from "@mui/lab/DatePicker";
import { useNavigate, useLocation } from "react-router-dom";
import Utils from "../utils";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor:
            theme.palette.mode === "light" ? colours.primary100 : colours.primary100,
    },
}));

function capcase(input) {
    return input.charAt(0).toUpperCase() + input.slice(1);
}

function App() {
    //const [topquestions] = useState("Agent");
    const [value, setValue] = React.useState(null);
    const [age] = React.useState("");
    let navigate = useNavigate();
    let location = useLocation();
    let camid = location.search.replace("?id=", "");
    //const [campaignID] = React.useState(camid);
    const [data, setData] = React.useState({});
    const [pldata, setPLData] = React.useState([]);
    const [sentiment, setSentiment] = React.useState([]);
    const [topicdata, settopicdata] = React.useState([]);
    const [listofagents, setListofagents] = React.useState([]);
    const [allscore, setAllscore] = React.useState(0);
    const [spinner, setSpinner] = React.useState(false);
    const [pickedAgent, setPickedAgent] = React.useState(true);
    const [pickedAgentTOP, setPickedAgentTOP] = React.useState(true);
    async function getCampaignData() {
        Utils.sendSnackBar("Getting records");

        setSpinner(true);

        let user = await Utils.getUserGroupFromCognito();
        user = user.filter((z) => z !== "admin");
        // console.log({ user });
        let company = await Utils.genericList("listCompanies", {
            filter: { internalname: { eq: user[0] } },
        });
        company = company.data.listCompanies.items[0].id;
        // console.log({ company });
        company = await Utils.genericGet("getCompany", company);
        // console.log({ company });
        let s = company.data.getCompany;

        s.VShits = JSON.parse(s.VShits);
        let score = s.VShits.VS / s.VShits.n;
        // console.log({ s });
        setAllscore(score);
        s.agenthits = JSON.parse(s.agenthits);
        s.alerthits = JSON.parse(s.alerthits);
        s.durationhits = JSON.parse(s.durationhits);
        s.topichits = JSON.parse(s.topichits);
        // console.log({ topics: s.topichits });
        let newtopicdata = s.topichits
            .map((ss) => {
                let sen = "neu";
                if(ss.s === "pos") sen = "pos";
                if(ss.s === "neg") sen = "neg";
                return {
                    keyword: capcase(ss.topic) || capcase(ss.alert),
                    x: 10,
                    y: 10,
                    z: ss.n,
                    sen: sen,
                };
            })
            .filter((z) => z.z > 10);
        newtopicdata = newtopicdata.filter((z) => z.sen !== "neu").slice(0, 10);
        // newtopicdata.push(Object.assign({}, newtopicdata[0]));
        // newtopicdata.push(Object.assign({}, newtopicdata[0]));
        // newtopicdata.push(Object.assign({}, newtopicdata[0]));
        // newtopicdata.push(Object.assign({}, newtopicdata[0]));
        // newtopicdata.push(Object.assign({}, newtopicdata[0]));
        // newtopicdata.push(Object.assign({}, newtopicdata[0]));
        // newtopicdata.push(Object.assign({}, newtopicdata[0]));
        // newtopicdata.push(Object.assign({}, newtopicdata[0]));

        // newtopicdata[2].z = 10;
        // newtopicdata[3].z = 30;
        // newtopicdata[2].sen = "neg";
        // newtopicdata[3].sen = "neg";
        //
        // newtopicdata[4].z = 30;
        // newtopicdata[2].keyword = "KJSHADKJHSD";
        // newtopicdata[3].keyword = "fgkhjfkldhg";
        // newtopicdata[4].keyword = "bndfbn875";
        // newtopicdata[5].keyword = "sfrger875";
        // newtopicdata[6].keyword = "h4646hs";
        // newtopicdata[8].keyword = "bre5aw";
        // newtopicdata[9].keyword = "vbwe4ge5";

        // function collision(i, j) {
        //   let distance =
        //     Math.sqrt((i.x - j.x) * (i.x - j.x) + (i.y - j.y) * (i.y - j.y)) -
        //     i.z / 15 -
        //     j.z / 15;
        //   // console.log({ distance, i, j });
        //   return distance < 0.6;
        // }

        if(newtopicdata.length > 0) newtopicdata[0].x = 10;
        if(newtopicdata.length > 0) newtopicdata[0].y = 10;
        if(newtopicdata.length > 1) newtopicdata[1].x = 12.5;
        if(newtopicdata.length > 1) newtopicdata[1].y = 11;
        if(newtopicdata.length > 2) newtopicdata[2].x = 10;
        if(newtopicdata.length > 2) newtopicdata[2].y = 17;
        if(newtopicdata.length > 3) newtopicdata[3].x = 15;
        if(newtopicdata.length > 3) newtopicdata[3].y = 12;
        if(newtopicdata.length > 4) newtopicdata[4].x = 7;
        if(newtopicdata.length > 4) newtopicdata[4].y = 16;
        if(newtopicdata.length > 5) newtopicdata[5].x = 18;
        if(newtopicdata.length > 5) newtopicdata[5].y = 12;
        if(newtopicdata.length > 6) newtopicdata[6].x = 6;
        if(newtopicdata.length > 6) newtopicdata[6].y = 5;
        if(newtopicdata.length > 7) newtopicdata[7].x = 12;
        if(newtopicdata.length > 7) newtopicdata[7].y = 5;
        if(newtopicdata.length > 8) newtopicdata[8].x = 13;
        if(newtopicdata.length > 8) newtopicdata[8].y = 18;
        if(newtopicdata.length > 9) newtopicdata[9].x = 4;
        if(newtopicdata.length > 9) newtopicdata[9].y = 9;
        //
        // console.log(collision(newtopicdata[4], newtopicdata[3]));
        //
        // let finallist = [newtopicdata[0]];
        //
        // function collisionall(item) {
        //   let p = finallist.map((z) => collision(z, item));
        //   console.log({ p });
        //   let p2 = p.filter((z) => z);
        //   console.log({ p2 });
        //   return p2.length > 0;
        // }
        //
        // newtopicdata.forEach((z, zi) => {
        //   if (zi > 0) {
        //     if (!collisionall(z)) finallist.push(z);
        //   }
        // });
        // console.log({ finallist });

        // newtopicdata.forEach((z, zi) => {
        //   if (zi > 0 && zi < 5) {
        //     z.x = Math.random() * 12 +4;
        //     z.y = Math.random() * 12 +4 ;
        //   }
        //   // while (!distanceall(newtopicdata, z)){
        //   //   z.x = Math.random() * 12 +4;
        //   //   z.y = Math.random() * 12 +4 ;
        //   // }
        // });

        // console.log({ newtopicdata });


        console.log(topicdata);
        console.log(newtopicdata);

        settopicdata(newtopicdata);

        s.sentimenthits = JSON.parse(s.sentimenthits);
        s.trackerhits = JSON.parse(s.trackerhits);
        s.questionhits = JSON.parse(s.questionhits);

        s.analyticshits = JSON.parse(s.analyticshits);

        let today = new Date(Date.now());
        today = today.toISOString().substr(0, 7);
        let last30 = new Date(Date.now());
        last30.setMonth(last30.getMonth() - 1);
        last30 = last30.toISOString().substr(0, 7);
        let last60 = new Date(Date.now());
        last60.setMonth(last60.getMonth() - 2);
        last60 = last60.toISOString().substr(0, 7);

        let todayvals = s.sentimenthits.find((z) => z.month === today);
        let l30vals = s.sentimenthits.find((z) => z.month === last30);
        let l60vals = s.sentimenthits.find((z) => z.month === last60);


        // let totalsentiment =
        //   s.sentimenthits.neg + s.sentimenthits.pos + s.sentimenthits.neu;
        //

        let senti = [];
        if(todayvals) {
            senti.push({
                name: todayvals.month,
                Negative:
                    (todayvals.values.neg /
                        (todayvals.values.neg +
                            todayvals.values.pos +
                            todayvals.values.neu)) *
                    100,
                Positive:
                    (todayvals.values.pos /
                        (todayvals.values.neg +
                            todayvals.values.pos +
                            todayvals.values.neu)) *
                    100,
            });
        } else {
            senti.push(null);
        }
        if(l30vals) {
            senti.push({
                name: l30vals.month,
                Negative:
                    (l30vals.values.neg /
                        (l30vals.values.neg + l30vals.values.pos + l30vals.values.neu)) *
                    100,
                Positive:
                    (l30vals.values.pos /
                        (l30vals.values.neg + l30vals.values.pos + l30vals.values.neu)) *
                    100,
            });
        } else {
            senti.push(null);
        }
        if(l60vals) {
            senti.push({
                name: l60vals.month,
                Negative:
                    (l60vals.values.neg /
                        (l60vals.values.neg + l60vals.values.pos + l60vals.values.neu)) *
                    100,
                Positive:
                    (l60vals.values.pos /
                        (l60vals.values.neg + l60vals.values.pos + l60vals.values.neu)) *
                    100,
            });
        } else {
            senti.push(null);
        }
        setSentiment(senti.reverse());
        // [
        //   {
        //     name: todayvals.month,
        //     Negative:
        //       ((.neg / totalsentiment) * 100).toFixed(0) + "%",
        //     Neutral:
        //       ((s.sentimenthits.neu / totalsentiment) * 100).toFixed(0) + "%",
        //     Positive:
        //       ((s.sentimenthits.pos / totalsentiment) * 100).toFixed(0) + "%",
        //   },
        // ]);
        // setSentiment([
        //   {
        //     name: "Negative",
        //     Negative: (s.sentimenthits.neg / totalsentiment) * 100,
        //   },
        //   {
        //     name: "Neutral",
        //
        //     Neutral: (s.sentimenthits.neu / totalsentiment) * 100,
        //   },
        //   {
        //     name: "Positive",
        //
        //     Positive: (s.sentimenthits.pos / totalsentiment) * 100,
        //   },
        // ]);
        setData(s);
        setSpinner(false);
        let pl = await Utils.genericListMOD2(
            "listConversations",
            {
                filter: { bookmarks: { attributeExists: true } },
                limit: 1000,
            },
            true
        );
        setPLData(pl.data.listConversations.items);
        let conv = await Utils.genericListMOD3(null);
        let nt = conv.data.listConversations.nextToken;

        while(nt !== null) {
            let newload = await Utils.genericListMOD3(nt);
            nt = newload.data.listConversations.nextToken;
            conv.data.listConversations.items = conv.data.listConversations.items.concat(
                newload.data.listConversations.items
            );
        }

        conv = conv.data.listConversations;
        conv = conv.items.filter((z) => z.VS !== null);
        let agentslist = [];
        conv.forEach((z) => {
            let found = agentslist.find((a) => a.agent === z.agent);
            if(found) {
                found.VS += parseInt(z.VS);
                found.n += 1;
            } else {
                agentslist.push({ agent: z.agent, n: 1, VS: parseInt(z.VS) });
            }
        });

        setListofagents(
            agentslist.map((z) => {
                return {
                    agent: z.agent,
                    VS: z.VS / z.n,
                };
            })
        );
        Utils.sendSnackBar("Done");
    }
    React.useEffect(() => {
        getCampaignData();
        window.scrollTo(0, 0);
    }, []);


    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var sentimentdata = sentiment;
    //sentimentdata = sentimentdata.filter(x => x !== null);
    sentimentdata.map((senti, i) => {
        if(senti != null) {
            if(month.indexOf(senti.name) > -1) {
                //do nothing
            } else {
                var d = new Date(senti.name);
                var datename = month[d.getMonth()];
                sentimentdata[i].name = datename;
            }
        }
    });
    const barColors = [
        ["#21A500", "#1B8700"],
        ["#DEE2F1", "#999DAC"],
        ["#E7A500", "#E87522"],
    ];

    return (
        <div className="vocalysed-home analytics"
            style={{
                display: "flex",
                flexDirection: "row",
                minHeight: "100vh",
            }}
        >
            <div
                style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div style={colours.vboxMediumTall2} className="vocalysd-card">
                    <div className="vocalysd-heading">
                        Overall sentiment
                        <TT
                            info={
                                "Sentiment indicates the attitude or tone of the Speaker at a point in the Conversation. It can be positive or negative."
                            }
                        />
                    </div>
                    <div style={{ width: "100%", height: 310 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={500}
                                height={300}
                                data={sentimentdata}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="name"
                                    axisLine={{ stroke: "#DEE2F1" }}
                                    fontFamily={"Poppins"}
                                />
                                <YAxis
                                    axisLine={{ stroke: "#DEE2F1" }}
                                    fontFamily={"Poppins"}
                                    tickFormatter={(d) => {
                                        return d.toFixed(0) + "%";
                                    }}
                                />
                                <Tooltip
                                    formatter={(v, n, p) => {
                                        return v.toFixed(0) + "%";
                                    }}
                                />
                                <defs>
                                    {barColors.map((color, index) => (
                                        <linearGradient
                                            id={`colorUv${index}`}
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="100%"
                                            spreadMethod="reflect"
                                        >
                                            <stop offset="0" stopColor={color[0]} />
                                            <stop offset="1" stopColor={color[1]} />
                                        </linearGradient>
                                    ))}
                                </defs>

                                <Bar dataKey="Positive" stackId="a" fill={colours.green100} radius={0}>
                                    {sentimentdata.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={`url(#colorUv0)`} />
                                    ))}
                                </Bar>
                                <Bar dataKey="Negative" stackId="a" fill={colours.primary100} radius={[10, 10, 0, 0]}>
                                    {sentimentdata.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={`url(#colorUv2)`} />
                                    ))}
                                </Bar>
                            </BarChart>

                        </ResponsiveContainer>
                    </div>
                </div>
                <div style={colours.vboxMediumTall2} className="vocalysd-card">
                    <div className="vocalysd-heading">
                        Topic based sentiment
                        <TT
                            info={
                                "Topics are words or phrases that come up frequently across Conversations, and carry a Sentiment."
                            }
                        />
                    </div>
                    <div style={{ width: "100%", height: 310 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <ScatterChart
                                width={400}
                                height={200}
                                margin={{
                                    top: 20,
                                    right: 20,
                                    bottom: 20,
                                    left: 20,
                                }}
                            >
                                <XAxis
                                    type="number"
                                    dataKey="x"
                                    axisLine={false}
                                    hide={true}
                                    range={[1, 10000]}
                                    domain={[1, 20]}
                                    scale="linear"
                                />
                                <YAxis
                                    type="number"
                                    dataKey="y"
                                    axisLine={false}
                                    hide={true}
                                    range={[1, 10000]}
                                    domain={[1, 20]}
                                    scale="linear"
                                />
                                <ZAxis
                                    type="number"
                                    dataKey="z"
                                    range={[1, 10000]}
                                    domain={[1, 20]}
                                    scale="linear"
                                />

                                <Scatter
                                    name="Negative"
                                    data={topicdata.filter((z) => z.sen === "neg")}
                                    fill={colours.primary100}
                                >
                                    <LabelList
                                        dataKey="keyword"
                                        fill="#fff"
                                        style={{ fontFamily: "Poppins", fontSize: 12 }}
                                    />
                                </Scatter>

                                <Scatter
                                    name="Positive"
                                    data={topicdata.filter((z) => z.sen === "pos")}
                                    fill={colours.green100}
                                >
                                    <LabelList
                                        dataKey="keyword"
                                        fill="#fff"
                                        style={{ fontFamily: "Poppins", fontSize: 12 }}
                                    />
                                </Scatter>
                            </ScatterChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="vocalysd-card">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div className="vocalysd-heading">
                            Agent analytics
                            <TT info={"Shows the top performing and bottom performing Agents."} />
                        </div>
                        <ButtonGroup
                            variant="contained"
                            aria-label="outlined primary button group"
                            className="buttons-border-extend"
                        >
                            <Button
                                onClick={() => setPickedAgentTOP(true)}
                                style={{
                                    textTransform: "none",
                                    background: pickedAgentTOP
                                        ? colours.primary100
                                        : colours.white,
                                    boxShadow: pickedAgentTOP
                                        ? "none"
                                        : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                                    color: pickedAgentTOP ? colours.white : colours.black,
                                    fontFamily: "Poppins",
                                }}
                            >
                                Top performers
                            </Button>
                            <Button
                                style={{
                                    textTransform: "none",
                                    background: !pickedAgentTOP
                                        ? colours.primary100
                                        : colours.white,
                                    boxShadow: !pickedAgentTOP
                                        ? "none"
                                        : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                                    color: !pickedAgentTOP ? colours.white : colours.black,
                                    fontFamily: "Poppins",
                                }}
                                onClick={() => setPickedAgentTOP(false)}
                            >
                                Bottom performers
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div style={{ marginTop: 16 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Agent</TableCell>

                                    <TableCell align="right">Avg. Vocalysd score</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!listofagents ? (
                                    <TableRow />
                                ) : (
                                    listofagents
                                        .sort((a, b) => {
                                            if(pickedAgentTOP) return b.VS - a.VS;
                                            else return a.VS - b.VS;
                                        })
                                        .slice(0, 7)
                                        .map((z, zi) => (
                                            <TableRow key={zi}>
                                                <TableCell
                                                    style={{
                                                        paddingTop: 3,
                                                        paddingBottom: 3,
                                                        fontFamily: "Poppins",
                                                    }}
                                                >
                                                    {zi + 1 + ". " + z.agent}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    style={{
                                                        paddingTop: 3,
                                                        paddingBottom: 3,
                                                        fontFamily: "Poppins",
                                                    }}
                                                >
                                                    {z.VS.toFixed(0)}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div /*style={colours.vboxMedium}*/ className="vocalysd-card">
                    <div className="vocalysd-heading">
                        Overall Vocalysd score
                        <TT
                            info={
                                "The Vocalysd Score is the overall health metric assigned to a Conversation. The higher the percentage value, the better."
                            }
                        />
                    </div>

                    <div style={{ width: "100%", marginTop: 20 }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: "8px",
                            }}
                        >
                            <div style={{ color: colours.subduedgray }}>
                                <span style={{ fontSize: 20, color: "#000", fontWeight: "600" }}>
                                    {allscore.toFixed(0) + "%"}
                                </span>{" "}
                                Accurate
                            </div>
                            <div style={{ color: colours.subduedgray, marginRight: "50px" }}>
                                <span style={{ fontSize: 20, color: "#000", fontWeight: "600" }}>{"90%"}</span>{" "}
                                Target
                            </div>
                        </div>
                        <Box sx={{ color: colours.primary100 }}>
                            <BorderLinearProgress
                                variant="determinate"
                                color="inherit"
                                value={allscore}
                            />
                        </Box>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexShrink: 1,
                        flexDirection: "row",
                    }}
                >
                    <div style={colours.vboxSmall} className="vocalysd-card">
                        <div className="vocalysd-heading">
                            Compliance
                            <TT
                                info={
                                    "Compliance is based on a User-set checklist of things the Agent needs to cover in a call. Higher % is better."
                                }
                            />
                        </div>
                        <div>
                            <div
                                style={{ display: "flex", flexDirection: "row", marginTop: 16 }}
                            >
                                <div style={{ marginRight: 50 }}>
                                    <div style={{ fontSize: 12, color: "#999DAC" }}>Legal</div>
                                    <div style={{ fontSize: 30, fontWeight: "600" }}>
                                        {!data.trackerhits
                                            ? 0
                                            : data.trackerhits.find(
                                                (z) => z.tracker === "legal-detect"
                                            )
                                                ? (data.trackerhits.find(
                                                    (z) => z.tracker === "legal-detect"
                                                ).hits / data.VShits.n * 100).toFixed(0)
                                                : 0}
                                        %
                                    </div>
                                </div>
                                <div style={{ marginRight: 5 }}>
                                    <div style={{ fontSize: 12, color: "#999DAC" }}>Consent</div>
                                    <div style={{ fontSize: 30, fontWeight: "600" }}>
                                        {!data.trackerhits
                                            ? 0
                                            : data.trackerhits.find(
                                                (z) => z.tracker === "consent-detect"
                                            )
                                                ? (data.trackerhits.find(
                                                    (z) => z.tracker === "consent-detect"
                                                ).hits / data.VShits.n * 100).toFixed(0)
                                                : 0}
                                        %
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={colours.vboxSmall} className="vocalysd-card">
                        <div className="vocalysd-heading">
                            Top trackers
                            <TT info={"Trackers that came up most frequently."} />
                        </div>
                        <div style={{ marginTop: 16 }}>
                            {!data.trackerhits
                                ? null
                                : data.trackerhits
                                    .slice(0, 8)
                                    .map((z, zx) => (
                                        <Chip
                                            label={z.tracker}
                                            key={zx}
                                            style={{ margin: 1, fontFamily: "Poppins" }}
                                        />
                                    ))}
                        </div>
                    </div>
                </div>
                <div style={colours.vboxMedium} className="vocalysd-card">
                    <div>
                        <div
                            style={{
                                display: "flex",

                                alignItems: "center",
                                fontSize: 16,
                                marginBottom: 20,
                            }}
                            className="vocalysd-heading">
                            Playlists
                            <TT
                                info={
                                    "A quick indication of how many items are categorised in key Playlists."
                                }
                            />
                            <ChevronRightIcon
                                style={{ color: "#E7A500", cursor: "pointer" }}
                                onClick={() => navigate("/playlists")}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ borderLeft: "3px solid #21A500", paddingLeft: 10 }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ fontSize: 12, color: "#999DAC" }}>
                                        WOW moments
                                    </div>
                                </div>
                                <div
                                    style={{
                                        fontSize: 30,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{ fontSize: 30 }}>
                                        {!pldata
                                            ? null
                                            : pldata.filter(
                                                (z) => z.bookmarks.search("WOW moments") > -1
                                            ).length}
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    borderLeft: "3px solid #E87522",
                                    paddingLeft: 10,
                                    marginLeft: 200,
                                }}
                            >
                                <div style={{ display: "flex" }}>
                                    <div style={{ fontSize: 12, color: "#999DAC" }}>
                                        High risk
                                    </div>
                                </div>
                                <div
                                    style={{
                                        fontSize: 30,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{ fontSize: 30 }}>
                                        {!pldata
                                            ? null
                                            : pldata.filter(
                                                (z) => z.bookmarks.search("High risk") > -1
                                            ).length}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={colours.vboxSmall} className="vocalysd-card">
                        <div className="vocalysd-heading">
                            Talk ratio{" "}
                            <TT
                                info={
                                    "The ratio between, Agent speaking time, Client speaking time, and silence in a recording."
                                }
                            />
                        </div>
                        <div>
                            {!data.analyticshits ? null : (
                                <div style={{}}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <div style={{ marginRight: 5 }}>
                                            <div style={{ fontSize: 30, fontWeight: "600" }}>
                                                {(
                                                    (data.analyticshits.agenttalktime /
                                                        (data.analyticshits.agenttalktime +
                                                            data.analyticshits.clienttalktime +
                                                            data.analyticshits.silence)) *
                                                    100
                                                ).toFixed(0)}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 12,
                                                    color: "#999DAC",
                                                    fontFamily: "Poppins",
                                                }}
                                            >
                                                Agent
                                            </div>
                                        </div>
                                        <div style={{ marginRight: 5 }}>
                                            <div style={{ fontSize: 30, fontWeight: "600" }}>:</div>
                                        </div>

                                        <div style={{ marginRight: 5 }}>
                                            <div style={{ fontSize: 30, fontWeight: "600" }}>
                                                {(
                                                    (data.analyticshits.clienttalktime /
                                                        (data.analyticshits.agenttalktime +
                                                            data.analyticshits.clienttalktime +
                                                            data.analyticshits.silence)) *
                                                    100
                                                ).toFixed(0)}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 12,
                                                    color: "#999DAC",
                                                    fontFamily: "Poppins",
                                                }}
                                            >
                                                Client
                                            </div>
                                        </div>
                                        <div style={{ marginRight: 5 }}>
                                            <div style={{ fontSize: 30, fontWeight: "600" }}>:</div>
                                        </div>

                                        <div style={{ marginRight: 5 }}>
                                            <div style={{ fontSize: 30, fontWeight: "600" }}>
                                                {(
                                                    (data.analyticshits.silence /
                                                        (data.analyticshits.agenttalktime +
                                                            data.analyticshits.clienttalktime +
                                                            data.analyticshits.silence)) *
                                                    100
                                                ).toFixed(0)}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 12,
                                                    color: "#999DAC",
                                                    fontFamily: "Poppins",
                                                }}
                                            >
                                                Silence
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={colours.vboxSmall} className="vocalysd-card">
                        <div className="vocalysd-heading">
                            Top alerts
                            <TT info={"The Alerts most frequently triggered."} />
                        </div>
                        <div>
                            {!data.alerthits
                                ? null
                                : data.alerthits.map((z, zx) => (
                                    <Chip
                                        label={z.alert}
                                        key={zx}
                                        style={{ margin: 1, fontFamily: "Poppins" }}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
                <div className="vocalysd-card">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div className="vocalysd-heading">
                            Top questions
                            <TT info={"The top questions asked by Agents and Clients."} />
                        </div>
                        <ButtonGroup
                            variant="contained"
                            aria-label="outlined primary button group"
                            className="buttons-border-extend"
                        >
                            <Button
                                onClick={() => setPickedAgent(true)}
                                style={{
                                    background: pickedAgent ? colours.primary100 : colours.white,
                                    boxShadow: pickedAgent
                                        ? "none"
                                        : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                                    color: pickedAgent ? colours.white : colours.black,
                                    textTransform: "none",
                                    fontFamily: "Poppins",
                                }}
                            >
                                Agent
                            </Button>
                            <Button
                                style={{
                                    background: !pickedAgent ? colours.primary100 : colours.white,
                                    boxShadow: !pickedAgent
                                        ? "none"
                                        : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                                    color: !pickedAgent ? colours.white : colours.black,
                                    textTransform: "none",
                                    fontFamily: "Poppins",
                                }}
                                onClick={() => setPickedAgent(false)}
                            >
                                Client
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div style={{ marginTop: 16 }}>
                        <Table>
                            <TableBody>
                                {!data.questionhits ? (
                                    <TableRow />
                                ) : (
                                    data.questionhits
                                        .filter((z) =>
                                            pickedAgent ? z.speaker === "Speaker 2" : "Speaker 1"
                                        )
                                        .sort((a, b) => b.n - a.n)
                                        .slice(0, 7)
                                        .map((z, zi) => (
                                            <TableRow key={zi}>
                                                <TableCell
                                                    style={{
                                                        paddingTop: 3,
                                                        paddingBottom: 3,
                                                        fontFamily: "Poppins",
                                                    }}
                                                >
                                                    {zi + 1 + ". " + z.text}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
            {true ? null : (
                <div style={{ width: "20%", background: "#fff", marginLeft: 10 }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                        }}
                    >
                        <div>Filters</div>
                        <Button style={{ textTransform: "none" }}>Reset all</Button>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                        }}
                    >
                        <div>DATE RANGE</div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="From"
                                InputLabelProps={{
                                    style: { color: '#84858a' },
                                }}
                                value={value}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                label="To"
                                InputLabelProps={{
                                    style: { color: '#84858a' },
                                }}
                                value={value}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <div>Limit to</div>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Entity"
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Campaigns"
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Agents"
                                InputLabelProps={{
                                    style: { color: '#84858a' },
                                }}
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <Button style={{ textTransform: "none" }} variant="outlined">
                            Apply
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;
