import React, { useEffect, useState } from "react";
import Badge from "@mui/material/Badge";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Amplify, { Hub } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import AppBar from "@mui/material/AppBar";
import Home from "./pages/home";
import Admin from "./pages/admin";
import Profile from "./pages/profile";
import Campaigns from "./pages/campaigns";
import Campaign from "./pages/campaign";
import Conversation from "./pages/conversation";
import Login from "./pages/login";
import Organisation from "./pages/organisation";
import OrganisationSetup from "./pages/organisation-setup";
import Playlists from "./pages/playlists";
import Privacy from "./pages/privacy";
import Settings from "./pages/settings";
import Terms from "./pages/terms";
import AdminUsers from "./pages/admin-users";
import Trackers from "./pages/trackers";
import TrackerAdmin from "./pages/tracker-admin";
import Alerts from "./pages/alerts";
import Tracker from "./pages/tracker";
import Snackbar from "@mui/material/Snackbar";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import awsconfig from "./aws-exports";
import ProjectVersionInput from "../package.json";
import { styled } from "@mui/material/styles";
import LogoWhite from "./pages/images/Logo_white.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { colours } from "./consts";
import Utils from "./utils";
import IconButton from "@mui/material/IconButton";
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

function TT({ info }) {
    return (
        <HtmlTooltip
            title={
                <React.Fragment>
                    <Typography color="inherit" style={{ fontFamily: "Poppins" }}>
                        {info}
                    </Typography>
                </React.Fragment>
            }
        >
            <IconButton style={{ marginLeft: 30, marginTop: "-8px" }}>
                <FeatherIcon icon="info" style={{ color: "#e7a500", width: 20, height: 20 }} />
            </IconButton>
        </HtmlTooltip>
    );
}

const pages = [
    {
        path: "/trackers",
        name: "Trackers",
        tooltip:
            "A Tracker is a classification of words, phrases, and/or expressions used to identify key events or data in an audio file.",
    },
    { path: "/alerts", name: "Alerts" },
    { path: "/tracker", name: "Tracker" },
    { path: "/trackeradmin", name: "Tracker admin" },
    {
        path: "/",
        name: "Analytics",
        tooltip:
            "This is where you can find your Dashboard containing the Vocalysd Insights.",
    },
    {
        path: "/admin",
        name: "Admin",
        tooltip:
            "The Admin page allows the Admin user to add Companies, Users, Trackers, etc. across an Organisation.",
    },
    { path: "/adminusers", name: "Admin users" },
    {
        path: "/campaigns",
        name: "Campaigns",
        tooltip:
            "Campaigns are custom groupings for Conversations, e.g. a promotion, a sales drive, queries, retention, etc.",
    },
    { path: "/campaign", name: "Campaign" },
    { path: "/profile", name: "Profile" },
    {
        path: "/organisation",
        name: "Organisation",
        tooltip:
            "An Organisation is also referred to as a Company, and is the primary entity to which audio files and Users are assigned.",
    },
    { path: "/organisationsetup", name: "Organisation setup" },
    {
        path: "/conversation",
        name: "Conversation",
        tooltip:
            "Conversations are based on audio files where 2 or more people have been recorded having the relevant conversation. ",
    },
    {
        path: "/playlists",
        name: "Playlists",
        tooltip:
            "Playlists are collections of audio snippets, generated through tagging or ‘bookmarking’ messages in a Transcript, based on playlists categories.",
    },
    { path: "/login", name: "Vocalysd" },
    { path: "/terms", name: "Terms and conditions" },
    { path: "/privacy", name: "Privacy policy " },
    { path: "/settings", name: "Settings" },
];
const ProjectVersion = ProjectVersionInput.version;
awsconfig.aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS";

awsconfig.oauth = {
    domain: "userpass.vocalysd.ai",
    scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
    ],

    responseType: "code",
};

/*if(!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    awsconfig.oauth.redirectSignIn = "https://192.168.0.100:3000/";
    awsconfig.oauth.redirectSignOut = "https://192.168.0.100:3000/";
} else {*/
    awsconfig.oauth.redirectSignIn = "https://vault.vocalysd.ai/";
    //awsconfig.oauth.redirectSignOut = "https://vault.vocalysd.ai/";
//}
Amplify.configure(awsconfig);

const sizeOfMenuDrawer = 100;

function DrawerBar({ admincheck }) {
    const [pagename, setPagename] = useState("Analytics");
    let history = useLocation();
    useEffect(() => {
        const pages = [
            { path: "/trackers", name: "Trackers" },
            { path: "/alerts", name: "Alerts" },
            { path: "/tracker", name: "Tracker" },
            { path: "/", name: "Analytics" },
            { path: "/admin", name: "Admin" },
            { path: "/campaigns", name: "Campaigns" },
            { path: "/trackeradmin", name: "Tracker admin" },
            { path: "/campaign", name: "Campaigns" },
            { path: "/adminusers", name: "Admin users" },
            { path: "/organisation", name: "Organisation" },
            { path: "/conversation", name: "Campaigns" },
            { path: "/playlists", name: "Playlists" },
            { path: "/profile", name: "Profile" },
            { path: "/trackers", name: "Trackers" },
            { path: "/trackers", name: "Trackers" },
            { path: "/organisationsetup", name: "Organisation setup" },
        ];
        if(pages.find((z) => z.path === history.pathname))
            window.scrollTo(0, 0);
        setPagename(pages.find((z) => z.path === history.pathname).name);
    }, [history.pathname]);

    // console.log(history);
    return (
        <Drawer
            anchor={"left"}
            variant="permanent"
            open={true}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    borderRight: 0,
                    boxSizing: "border-box",
                },
            }}
        >
            <div
                style={{
                    width: sizeOfMenuDrawer,
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    background: "#000",

                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        width: "100%",

                        display: "flex",
                        flexDirection: "column",

                        background: "#000",

                        overflow: "hidden",
                    }}
                >
                    <Link
                        to="/"
                        onClick={() => setPagename("Analytics")}
                        style={{ textDecoration: "none", marginTop: 80 }}
                    >
                        <div
                            style={
                                pagename === "Analytics"
                                    ? colours.menuStyleSelected
                                    : colours.menuStyle
                            }
                        >
                            <img src={LogoWhite} alt="" style={{ width: 30, marginBottom: "2px" }} />
                            Analytics
                        </div>
                    </Link>
                    <Link
                        to="/campaigns"
                        onClick={() => setPagename("Campaigns")}
                        style={{ textDecoration: "none" }}
                    >
                        <div
                            style={
                                pagename === "Campaigns"
                                    ? colours.menuStyleSelected
                                    : colours.menuStyle
                            }
                        >
                            <FeatherIcon
                                icon="message-square"
                                style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }}
                            />
                            Campaigns
                        </div>
                    </Link>
                    <Link
                        to="/playlists"
                        onClick={() => setPagename("Playlists")}
                        style={{ textDecoration: "none" }}
                    >
                        <div
                            style={
                                pagename === "Playlists"
                                    ? colours.menuStyleSelected
                                    : colours.menuStyle
                            }
                        >
                            <FeatherIcon icon="play-circle" style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }} />
                            Playlists
                        </div>
                    </Link>
                    <Link
                        to="/trackers"
                        onClick={() => setPagename("Trackers")}
                        style={{ textDecoration: "none" }}
                    >
                        <div
                            style={
                                pagename === "Trackers"
                                    ? colours.menuStyleSelected
                                    : colours.menuStyle
                            }
                        >
                            <FeatherIcon icon="loader" style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }} />
                            Trackers
                        </div>
                    </Link>

                    <Link
                        to="/organisation"
                        onClick={() => setPagename("Organisation")}
                        style={{ textDecoration: "none" }}
                    >
                        <div
                            style={
                                pagename === "Organisation"
                                    ? colours.menuStyleSelected
                                    : colours.menuStyle
                            }
                        >
                            <FeatherIcon icon="briefcase" style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }} />
                            Organisation
                        </div>
                    </Link>

                    {admincheck ? (
                        <Link
                            to="/admin"
                            onClick={() => setPagename("Admin")}
                            style={{ textDecoration: "none" }}
                        >
                            <div
                                style={
                                    pagename === "Admin"
                                        ? colours.menuStyleSelected
                                        : colours.menuStyle
                                }
                            >
                                <FeatherIcon icon="edit" style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }} />
                                Admin
                            </div>
                        </Link>
                    ) : null}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >

                    </div>
                </div>
                <div>
                    <Link
                        to="/"
                        onClick={() => Auth.signOut()}
                        style={{ textDecoration: "none" }}
                    >
                        <div style={colours.menuStyle}>
                            <FeatherIcon icon="log-out" style={{ color: colours.white, width: "30px", height: "30px", marginBottom: "2px" }} />
                            Log out
                        </div>
                    </Link>
                    <div
                        style={{
                            color: colours.white,
                            fontSize: 12,
                            textAlign: "center",
                            marginBottom: 16,
                        }}
                    >
                        {"v" + ProjectVersion}
                    </div>
                    <div
                        style={{
                            width: "50%",
                            margin: "auto",
                            marginBottom: 16,
                            borderTop: "1px solid #fff",
                            textAlign: "center",
                        }}
                    ></div>
                    <Link
                        to="/privacy"
                        style={{
                            textDecoration: "none",
                            color: colours.white,
                            fontSize: 10,
                            display: "block",
                            textAlign: "center",
                            marginTop: "16px",
                            marginBottom: "30px",
                        }}
                    >
                        Privacy policy
                    </Link>
                </div>
            </div>
        </Drawer>
    );
}

function Header({ setIsadmin }) {
    let history = useLocation();
    let navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [coname, setConame] = React.useState("");
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const [companyDT, setCompanyDT] = React.useState([]);

    async function getCompanyData() {
        try {
            let user = await Utils.getUserGroupFromCognito();
            if(user.find((z) => z === "admin")) setIsadmin(true);
            user = user.filter((z) => z !== "admin");
            let company = await Utils.genericList("listCompanies", {
                filter: { internalname: { eq: user[0] } },
            });
            company = company.data.listCompanies.items[0].id;
            company = await Utils.genericGetMOD("getCompany", company, {
                before: "alerts",
                after: "alerts(limit:10000)",
            });
            // console.log({ company });
            setConame(company.data.getCompany.name);

            let usrnm = (
                await Utils.genericGet("getUser", (await Utils.getUserName()).username)
            ).data.getUser;
            if(usrnm && usrnm.firstname && usrnm.lastname)
                setUsername(usrnm.firstname + " " + usrnm.lastname);
            company = company.data.getCompany.alerts.items;
            company.forEach((z) => (z.createdAt = new Date(z.createdAt)));
            // console.log({ user, company, usrnm });
            setCompanyDT(company);
        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            //      console.log(event);
            switch(event) {
                case "signIn":
                case "cognitoHostedUI":
                    getUser().then((userData) => setUser(userData));
                    break;
                case "signOut":
                    setUser(null);
                    break;
                case "signIn_failure":
                case "cognitoHostedUI_failure":
                    console.log("Sign in failure", data);
                    break;
                default:
                    break;
            }
        });
        getCompanyData();
        getUser()
            .then((userData) => setUser(userData))
            .then((z) => Utils.getUserGroupFromCognito())
            .then((z) => Utils.setUserGroup(z))
            .catch((e) => console.log(e));
    }, []);
    // console.log({ user });

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((userData) => {
                Utils.setUser(userData.username);
                return userData;
            })

            .catch(() => Auth.federatedSignIn());/*console.log("Not signed in"));*/
    }
    return (
        <AppBar className={history.pathname === '/organisation'
            ? coname
            : pages.find((z) => z.path === history.pathname).name}
            position="static"
            style={{
                background: "transparent",
                height: 80,
                boxShadow: "none",
                // borderBottom: "2px solid #DEE2F1",
            }}
        >
            <Toolbar
                style={{
                    display: "flex",
                    background: "#fff",
                    color: "#000",
                    height: "100%",
                    flexDirection: "row",

                    justifyContent: "space-between",
                }}
            >
                <div style={{ fontSize: 32 }}>
                    {history.pathname === "/organisation"
                        ? coname
                        : pages.find((z) => z.path === history.pathname).name}
                    {pages.find((z) => z.path === history.pathname).tooltip ? (
                        <TT info={pages.find((z) => z.path === history.pathname).tooltip} />
                    ) : null}
                </div>
                <div>
                    {user ? (
                        <div>
                            <div
                                style={{
                                    textTransform: "none",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? "true" : undefined}
                                        onClick={handleClick}
                                        variant="transparent"
                                    >
                                        {companyDT.length > 0 ? (
                                            <Badge
                                                badgeContent={companyDT.length}
                                                sx={{
                                                    "& .MuiBadge-colorPrimary": {
                                                        background: colours.critical100,
                                                    },
                                                }}
                                                color="primary"
                                            >
                                                <FeatherIcon icon="bell" />
                                            </Badge>
                                        ) : (
                                            <FeatherIcon icon="bell" />
                                        )}
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            "aria-labelledby": "basic-button",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "Poppins",
                                                    padding: 16,
                                                    fontSize: 22,
                                                }}
                                            >
                                                Alerts
                                            </div>
                                            <div>
                                                <Button
                                                    style={{
                                                        textTransform: "none",
                                                        float: "right",
                                                        marginRight: 5,
                                                        borderRadius: 20,
                                                        fontWeight: "bold",
                                                        fontFamily: "Poppins",
                                                        background: colours.white,
                                                        color: colours.primary100,
                                                        border: "1px solid #ddd",
                                                        boxShadow: "none",
                                                    }}
                                                    variant="contained"
                                                    onClick={() => {
                                                        navigate("/alerts");
                                                        handleClose();
                                                    }}
                                                >
                                                    Go to alerts page
                                                </Button>
                                            </div>
                                        </div>
                                        {companyDT.map((z, zidx) => (
                                            <MenuItem
                                                onClick={() => {
                                                    navigate("/conversation?id=" + z.conversation);
                                                }}
                                                key={zidx}
                                                style={{
                                                    width: 400,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    // width: "100%",
                                                    justifyContent: "space-between",
                                                    borderBottom: "1px solid #fff",
                                                    background: colours.basegray,
                                                    padding: 16,
                                                }}
                                            >
                                                <div
                                                    style={{ display: "flex", flexDirection: "column" }}
                                                >
                                                    <div style={{ fontSize: 16 }}>{z.name}</div>
                                                    <div style={{ fontSize: 12 }}>{z.campaign}</div>
                                                </div>
                                                <div
                                                    style={{
                                                        textAlign: "right",
                                                        display: "flex",
                                                        alignSelf: "center",
                                                        fontSize: 10,
                                                    }}
                                                >
                                                    {moment(z.createdAt).fromNow()}
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>

                                <div>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? "true" : undefined}
                                        onClick={handleClick2}
                                        variant="transparent"
                                        style={{ textTransform: "none", fontSize: 16 }}
                                    >
                                        {username ? username : user.attributes.email}
                                        <FeatherIcon icon="chevron-down" />
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl2}
                                        open={open2}
                                        onClose={handleClose2}
                                        MenuListProps={{
                                            "aria-labelledby": "basic-button",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",

                                                padding: 16,
                                                width: 150,
                                            }}
                                        >
                                            <div style={{ fontSize: 16 }}>
                                                {username ? username : null}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 12,
                                                    color: colours.subduedgray,
                                                    fontFamily: "Poppins",
                                                }}
                                            >
                                                {user.attributes.email}
                                            </div>
                                        </div>

                                        <MenuItem
                                            onClick={() => {
                                                navigate("/profile");
                                            }}
                                            style={{
                                                padding: 16,
                                                display: "flex",
                                                flexDirection: "row",
                                                // width: "100%",

                                                borderBottom: "1px solid #fff",
                                                background: colours.basegray,
                                            }}
                                        >
                                            <FeatherIcon icon="user" style={{ marginRight: 16 }} />
                                            Profile
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                Auth.signOut();
                                            }}
                                            style={{
                                                padding: 16,
                                                display: "flex",
                                                flexDirection: "row",
                                                // width: "100%",

                                                borderBottom: "1px solid #fff",
                                                background: colours.basegray,
                                            }}
                                        >
                                            <FeatherIcon icon="log-out" style={{ marginRight: 16 }} />
                                            Log out
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => Auth.federatedSignIn()}
                            style={{
                                background: colours.primary100,
                                color: colours.white,
                                fontFamily: "Poppins",
                                textTransform: "none",
                            }}
                        >
                            Login
                        </Button>
                    )}
                </div>
            </Toolbar>
        </AppBar>
    );
}

function App() {
    const [snackText, setSnackText] = React.useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [isadmin, setIsadmin] = React.useState(false);
    const [isuser, setUser] = React.useState(false);

    useEffect(() => {
        Hub.listen("snackbar", ({ payload: { message } }) => {
            if(message === null) setSnackOpen(false);
            else {
                setSnackText(message);
                setSnackOpen(true);
            }
        });
    }, []);
    useEffect(() => {
        Utils.getUserName().then((z) => setUser(true));
    }, []);

    return (
        <Router>
            <DrawerBar admincheck={isadmin} />
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={() => setSnackOpen(false)}
                message={snackText}
                className="snack-bar"
            />
            <div style={{ marginLeft: sizeOfMenuDrawer }}>
                <Header setIsadmin={(d) => setIsadmin(d)} />

                <Routes>
                    {isuser ? (
                        <Route path="/" exact element={<Home />} />
                    ) : (
                        <Route path="/" exact element={<Login />} />
                    )}
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/adminusers" element={<AdminUsers />} />
                    <Route path="/campaigns" element={<Campaigns />} />
                    <Route path="/campaign" element={<Campaign />} />
                    <Route path="/conversation" element={<Conversation />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/organisation" element={<Organisation />} />
                    <Route path="/organisationsetup" element={<OrganisationSetup />} />
                    <Route path="/playlists" element={<Playlists />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/trackers" element={<Trackers />} />
                    <Route path="/trackeradmin" element={<TrackerAdmin />} />
                    <Route path="/alerts" element={<Alerts />} />
                    <Route path="/tracker" element={<Tracker />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/terms" element={<Terms />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
