import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { colours, tabselected, tabselectedNopoint } from "../consts.js";
import { useNavigate, useLocation } from "react-router-dom";
import Utils from "../utils";
import FeatherIcon from "feather-icons-react";

function App() {
  let navigate = useNavigate();
  let history = useLocation().search.replace("?id=", "");
  const [orgID] = useState(history);
  const [data, setData] = React.useState([]);
  const [selectedTab] = React.useState("Organisation setup");
  async function getCompanyData() {
    Utils.sendSnackBar("Please wait");
    let user = await Utils.getUserGroupFromCognito();
    if (user.find((z) => z === "admin") === null) navigate("/");
    else {
      let company = await Utils.genericGet("getCompany", orgID);
      company = company.data.getCompany;

        setData(company);
        Utils.sendSnackBar("Done");
    }
  }

  React.useEffect(() => {
    getCompanyData();
  },[]);

  // async function inviteuser(email, company) {
  //   let usr1 = await Utils.GenAdmFunc("adminCreateUser", {
  //     UserPoolId: "STRING_VALUE",
  //     Username: email,
  //     DesiredDeliveryMediums: ["EMAIL"],
  //   });
  //   await Utils.genericMutation("createUser", {
  //     id: usr1.User.Username,
  //   });
  //   await Utils.GenAdmFunc("adminAddUserToGroup", {
  //     GroupName: company.internalname,
  //     Username: usr1.User.Username,
  //     UserPoolId: "STRING_VALUE",
  //   });
  //
  //   getCompanyData();
  //   // console.log({ r });
  // }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FeatherIcon
          icon="award"
          style={{ marginLeft: 20, marginBottom: 10, color: "#aaa" }}
        />
        <div style={{ color: "#aaa", marginLeft: 5 }}>Admin /</div>
        <FeatherIcon icon="list" style={{ marginLeft: 5, marginBottom: 10 }} />
        <div style={{ marginLeft: 5 }}>Companies</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: colours.dividergraybottomborder,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <div
            style={tabselected(selectedTab === "Companies" || selectedTab === "Organisation setup")}
            onClick={() => {
              navigate("/admin");
            }}
          >
            Companies
          </div>
          <div
            style={tabselected(selectedTab === "Tracker admin")}
            onClick={() => {
              navigate("/trackeradmin");
            }}
          >
            Tracker admin
          </div>
          <div
            style={tabselected(selectedTab === "User setup")}
            onClick={() => {navigate("/adminusers");}}
          >
            User setup
          </div>
        </div>
      </div>
      <div className="vocalysed-home">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="vocalysd-card">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginBottom: 16 }}>Company details</div>
              <TextField
                style={{ width: 400, marginBottom: 16 }}
                id="outlined-basic"
                variant="outlined"
                label="Company name"
                InputLabelProps={{
                    style: { color: '#84858a' },
                }}               
                value={data.name || ""}
                onChange={(c) => {
                  let d = Object.assign({}, data);
                  d.name = c.target.value;
                  setData(d);
                }}
              />
              <div style={{ marginBottom: 16 }}>Admin details</div>
              <div style={{ marginBottom: 16 }}>
                <TextField
                  style={{ width: 400, marginRight: 16 }}
                  id="outlined-basic"
                  variant="outlined"
                label="Company contact"
                InputLabelProps={{
                    style: { color: '#84858a' },
                }}
                  value={data.contact || ""}
                  onChange={(c) => {
                    let d = Object.assign({}, data);
                    d.contact = c.target.value;
                    setData(d);
                  }}
                />
                <TextField
                  style={{ width: 400 }}
                  id="outlined-basic"
                  variant="outlined"
                label="Company email"
                InputLabelProps={{
                    style: { color: '#84858a' },
                }}
                  value={data.email || ""}
                  onChange={(c) => {
                    let d = Object.assign({}, data);
                    d.email = c.target.value;
                    setData(d);
                  }}
                />
              </div>
            </div>
            <div>
              <Button
                style={{
                  textTransform: "none",
                  float: "left",
                  marginTop: 16,
                  borderRadius: 20,
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  background: colours.primary100,
                  color: colours.white,
                  border: "1px solid #ddd",
                  boxShadow: "none",
                }}
                variant="contained"
                onClick={async () => {
                  Utils.sendSnackBar("Please wait");
                  await Utils.genericMutation("updateCompany", {
                    id: data.id,
                    name: data.name,
                    contact: data.contact,
                    email: data.email,
                  });
                  getCompanyData();
                }}
              >
                Save changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
