import React from "react";
import { colours, tabselected } from "../consts.js";
import CampaignInsights from "./campaign-insights";
import CampaignConversations from "./campaign-conversations";
import CampaignArchive from "./campaign-archived";
import { useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Utils from "../utils";
import UploadDialogue from "../components/upload/upload.js";
import Button from "@mui/material/Button";

function App() {
    let location = useLocation();
    let navigate = useNavigate();
    let camid = location.search.replace("?id=", "");

    const [uploadDiag, setUploadDiag] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [selectedTab, setSelectedTab] = React.useState("Campaign insights");
    const [campaignName, setCampaignName] = React.useState(camid);
    const [campaigndata, setCampaignData] = React.useState({
        name: "",
        type: "",
        company: { name: "" },
    });

    async function getCampaignData() {
        let camids = location.search.replace("?id=", "");
        let t = await Utils.genericGet("getCampaign", camids)
        setCampaignName(t.data.getCampaign.name);
        setCampaignData(t.data.getCampaign);
    }
    React.useEffect(() => {
        getCampaignData();
    }, []);

    function returnToCampaigns() {
        navigate("/campaigns")
    }

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <UploadDialogue
                input={{
                    company: "",
                    campaign: "",
                    type: "",
                }}
                campaigndata={campaigndata}
                output={(z) => {
                    setUploadDiag(false);
                    setData(
                        z.map((a) => {
                            return {
                                file: "",
                                agent: "",
                                calldate: "",
                            };
                        })
                    );
                }}
                open={uploadDiag}
                onClose={(z) => {
                    if(z !== null) {
                        setData(z);
                        setUploadDiag(false);
                    }
                }}
            />
            <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
                <FeatherIcon
                    icon="award"
                    style={{ marginLeft: 20, marginBottom: 10 }}
                />
                <div style={{ marginLeft: 5, cursor: "pointer" }}>
                    <div onClick={() => returnToCampaigns()}>Campaigns /</div>
                </div>
                <FeatherIcon icon="list" style={{ marginLeft: 5, marginBottom: 10 }} />
                <div className="menu-item-selected">{campaignName}</div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: colours.dividergraybottomborder,
                }}
            >
                <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                    <div
                        style={tabselected(selectedTab === "Campaign insights")}
                        onClick={() => setSelectedTab("Campaign insights")}
                    >
                        Campaign insights
                    </div>
                    <div
                        style={tabselected(selectedTab === "Processed conversations")}
                        onClick={() => setSelectedTab("Processed conversations")}
                    >
                        Processed conversations
                    </div>
                    <div
                        style={tabselected(selectedTab === "Archived")}
                        onClick={() => setSelectedTab("Archived")}
                    >
                        Archived
                    </div>
                    
                </div>
                <div style={{ paddingLeft: "calc(45vw)", paddingBottom: "10px", marginLeft: "16px" }}>
                    <Button
                        style={{
                            textTransform: "none",
                            float: "right",
                            borderRadius: 20,
                            marginRight: 20,
                            marginTop: 10,
                            background: colours.primary100,
                            fontFamily: "Poppins",
                        }}
                        variant="contained"
                        onClick={() => setUploadDiag(true)}
                    >
                        <FeatherIcon
                            icon="upload"
                            style={{ color: colours.white, marginRight: 8 }}
                        />
                        Upload
                    </Button>
                </div>
            </div>

            {selectedTab === "Campaign insights" ? <CampaignInsights /> : null}
            {selectedTab === "Processed conversations" ? (
                <CampaignConversations />
            ) : null}
            {selectedTab === "Archived" ? <CampaignArchive /> : null}
        </div>
    );
}

export default App;
