import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { colours, uploadTabSelected } from "../../consts.js";
import FeatherIcon from "feather-icons-react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Dropzone from "react-dropzone";
import Group192 from "../../pages/images/Group 192.png";
import Group195 from "../../pages/images/Group 195.png";
import Group76 from "../../pages/images/Group 76.png";
import Box from "@mui/material/Box";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Utils from "../../utils";
import Storage from "@aws-amplify/storage";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

function UploadDialogue({ open, onClose, input, output, campaigndata }) {
    // const [value, setValue] = React.useState(null);
    let today = new Date(Date.now()).toISOString().substr(0, 10);
    const [startDate, setStartDate] = React.useState(today);
    const [endDate, setEndDate] = React.useState(today);
    const [storeFiles, setStoreFiles] = React.useState(true);
    const [datePick, setDatePick] = React.useState("Today");
    const [files, setFiles] = React.useState([]);
    // const [files2, setFiles2] = React.useState([]);
    const [uploading, setUploading] = React.useState(false);
    const [agents, setAgents] = React.useState([]);
    const [selectedTab, setSelectedTab] = React.useState("Upload Audio");

    // const [progress, setProgress] = React.useState(0);
    const [maxFilesSizes, setMaxFilesSizes] = React.useState(0);
    const [totalLoaded, setTotalLoaded] = React.useState(0);

    function reset() {
        setUploading(false);
        setFiles([]);
        setMaxFilesSizes(0);
    }
    function closeDiag() {
        reset();;
    }

    return (
        <Dialog
            onClose={() => closeDiag(null)}
            onBackdropClick={() => {
                closeDiag(null);
            }}
            open={open}
            style={{
                padding: 0,

                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 30,
            }}
        >
            <DialogTitle style={{ padding: 0, minWidth: "600px", width: "600px" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 20,

                            fontFamily: "Poppins",
                        }}
                    >
                        <div>Upload conversations</div>

                        <FeatherIcon
                            icon="x"
                            style={{ color: "#ddd", cursor: "pointer" }}
                            onClick={() => closeDiag(null)}
                        />
                    </div>
                    <Divider style={{}} />
                </div>
            </DialogTitle>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 20,
                    paddingLeft: "80px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: 10,
                    }}
                >
                    <div
                        style={{
                            width: "max-content",
                            marginRight: 10,
                            height: 40,
                            background: "#FFFFFF",
                            alignItems: "center",
                            display: "flex",
                            color: colours.dividergray,
                            textAlign: "left",
                            flexDirection: "row",
                        }}
                    >
                        {input.company.substr(0, 1).toUpperCase()}
                    </div>
                    <FeatherIcon
                        icon="award"
                        style={{ marginBottom: 10 }}
                    />
                    <div>
                        <div style={{
                            fontSize: 12,
                            width: "max-content",
                            fontWeight: "bold"
                        }}
                        >
                            Campaign</div>
                        <div style={{ fontSize: 14 }}>{campaigndata.name || ""}</div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        width: 150,
                    }}
                >
                    <div
                        style={{
                            width: "125px",
                            marginRight: 10,
                            height: 40,
                            background: "#FFFFFF",
                            justifyContent: "none",
                            alignItems: "left",
                            display: "flex",
                            color: colours.black,
                            textAlign: "center",
                            flexDirection: "row",
                        }}
                    >                    </div>
                    <div>
                        <div style={{
                            fontSize: 12,
                            width: "max-content",
                            fontWeight: "bold"
                        }}
                        >Company</div>
                        <div style={{
                            fontSize: 14,
                            width: "max-content"
                        }}
                        >{campaigndata.company.name}</div>
                    </div>
                </div>

            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    marginRight: 10,
                    paddingTop: "30px",
                    fontFamily: "Poppins",
                    justifyContent: "space-evenly",
                }}
            >
                <div style={uploadTabSelected(selectedTab === "Upload Audio")} onClick={() => setSelectedTab("Upload Audio")}>Upload Audio</div>
                <div style={uploadTabSelected(selectedTab === "Upload Text")} onClick={() => setSelectedTab("Upload Text")}>Upload Text</div>
            </div>

            {/* Upload Audio*/}
            {selectedTab === "Upload Audio" ?
                <div>
                    <div style={{ margin: 50 }}>
                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                // console.log(acceptedFiles);
                                let totalsize = acceptedFiles
                                    .map((z) => z.size)
                                    .reduce((a, b) => (a = a + b), 0);
                                setMaxFilesSizes(totalsize);
                                setFiles(acceptedFiles);
                            }}
                            accept="audio/mpeg,audio/wav,audio/mp4,audio/*"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div
                                        {...getRootProps()}
                                        style={{
                                            background: "#F8F9FD",
                                            height: 117,
                                            boxShadow:
                                                "inset 4px 4px 5px #D1D9E6, inset -4px -4px 5px #FFFFFF",
                                            borderRadius: "20px",
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: 117,
                                                fontSize: 12,
                                                color: "#999DAC",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src={Group192}
                                                style={{ width: 96, height: 41 }}
                                            />
                                            <div>
                                                Drag & drop your audio files (WAV, MP3 or MP4) here, or <u>click to browse</u>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                    {files.length === 0 ? null : (
                        <div style={{ marginLeft: 20, marginRight: 20, fontSize: 12 }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <img alt="" src={Group76} style={{ width: 26, height: 34 }} />
                                <div style={{ width: "100%", paddingLeft: 5 }}>
                                    {!uploading ? (
                                        <div>{"Files: " + files.length + ""}</div>
                                    ) : (
                                        <div>
                                            {"Uploading: " +
                                                ((totalLoaded / maxFilesSizes) * 100).toFixed(0) +
                                                "%"}
                                        </div>
                                    )}
                                    <Box sx={{ color: colours.primary100 }}>
                                        <LinearProgress
                                            variant="determinate"
                                            color="inherit"
                                            value={(totalLoaded / maxFilesSizes) * 100}
                                        />
                                    </Box>
                                </div>
                            </div>
                            {uploading ? (
                                <div style={{ fontSize: 8 }}>
                                    Wait for upload to complete before closing this page...
                                </div>
                            ) : null}
                        </div>
                    )}
                    <div style={{ marginLeft: 20 }}>

                        {/*<div>*/}
                        {/*    <Switch*/}
                        {/*        style={{ color: colours.primary100 }}*/}
                        {/*        checked={storeFiles}*/}
                        {/*        disabled={true}*/}
                        {/*        onChange={(c) => {*/}
                        {/*            // console.log(c.target.checked);*/}
                        {/*            setStoreFiles(c.target.checked);*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*    Store a copy of the audio files*/}
                        {/*</div>*/}
                        {/*<FormControl>*/}
                        {/*    <RadioGroup*/}
                        {/*        row*/}
                        {/*        aria-labelledby="demo-row-radio-buttons-group-label"*/}
                        {/*        name="row-radio-buttons-group"*/}
                        {/*        onChange={(z) => setDatePick(z.target.value)}*/}
                        {/*        value={datePick}*/}
                        {/*    >*/}
                        {/*        <FormControlLabel*/}
                        {/*            value="Today"*/}
                        {/*            control={<Radio style={{ color: colours.primary100 }} />}*/}
                        {/*            label="Today"*/}
                        {/*        />*/}
                        {/*        <FormControlLabel*/}
                        {/*            value="Yesterday"*/}
                        {/*            control={<Radio style={{ color: colours.primary100 }} />}*/}
                        {/*            label="Yesterday"*/}
                        {/*        />*/}
                        {/*        <FormControlLabel*/}
                        {/*            value="Date range"*/}
                        {/*            control={<Radio style={{ color: colours.primary100 }} />}*/}
                        {/*            label="Date range"*/}
                        {/*        />*/}
                        {/*    </RadioGroup>*/}
                        {/*</FormControl>*/}

                        {/*<div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>*/}
                        {/*    <LocalizationProvider dateAdapter={AdapterDateFns}>*/}
                        {/*        <DatePicker*/}
                        {/*            label="Start date"*/}
                        {/*            value={startDate}*/}
                        {/*            disabled={datePick === "Date range" ? false : true}*/}
                        {/*            onChange={(newValue) => {*/}
                        {/*                setStartDate(newValue);*/}
                        {/*            }}*/}
                        {/*            renderInput={(params) => (*/}
                        {/*                <TextField*/}
                        {/*                    {...params}*/}
                        {/*                    sx={{*/}
                        {/*                        "& .MuiOutlinedInput-notchedOutline": {*/}
                        {/*                            borderColor: "#666",*/}
                        {/*                        },*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*        <DatePicker*/}
                        {/*            label="End date"*/}
                        {/*            value={endDate}*/}
                        {/*            disabled={datePick === "Date range" ? false : true}*/}
                        {/*            onChange={(newValue) => {*/}
                        {/*                setEndDate(newValue);*/}
                        {/*            }}*/}
                        {/*            renderInput={(params) => <TextField {...params} />}*/}
                        {/*        />*/}
                        {/*    </LocalizationProvider>*/}
                        {/*</div>*/}

                        {/*<FormControl>*/}
                        {/*    <RadioGroup*/}
                        {/*        row*/}
                        {/*        aria-labelledby="demo-row-radio-buttons-group-label"*/}
                        {/*        name="row-radio-buttons-group"*/}
                        {/*    >*/}
                        {/*        <FormControlLabel*/}
                        {/*            value="female"*/}
                        {/*            disabled={true}*/}
                        {/*            control={<Radio />}*/}
                        {/*            label="Inbound"*/}
                        {/*        />*/}
                        {/*        <FormControlLabel*/}
                        {/*            value="male"*/}
                        {/*            disabled={true}*/}
                        {/*            control={<Radio />}*/}
                        {/*            label="Outbound"*/}
                        {/*        />*/}
                        {/*    </RadioGroup>*/}
                        {/*</FormControl>*/}

                        <div style={{ margin: 25 }}>
                            <Dropzone
                                onDrop={async (acceptedFiles) => {
                                    if(acceptedFiles.length === 0)
                                        Utils.sendSnackBar("Please select a single file");
                                    async function rdr(flname) {
                                        return new Promise((resolve, reject) => {
                                            var reader = new FileReader();
                                            reader.onload = function(e) {
                                                var contents = e.target.result;
                                                let list = contents
                                                    .split("\n")
                                                    .filter((z) => z !== "")
                                                    .map((z) => {
                                                        let line = z.split(",");
                                                        return {
                                                            filename: line[0].trim(),
                                                            agent: line[1].trim(),
                                                        };
                                                    });
                                                resolve(list);
                                            };
                                            reader.readAsText(flname);
                                        });
                                    }
                                    setAgents(await rdr(acceptedFiles[0]));
                                }}
                                accept="text/csv"
                                maxFiles={1}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div
                                            {...getRootProps()}
                                            style={{
                                                background: "#F8F9FD",
                                                height: 117,
                                                boxShadow:
                                                    "inset 4px 4px 5px #D1D9E6, inset -4px -4px 5px #FFFFFF",
                                                borderRadius: "20px",
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    height: 117,
                                                    fontSize: 12,
                                                    color: "#999DAC",
                                                    cursor: "pointer"

                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    src={Group195}
                                                    style={{ width: 96, height: 41 }}
                                                />
                                                <div>
                                                    {agents.length > 0
                                                        ? "DONE"
                                                        : <>Drag & drop your agent linking file here or <u>click to browse.</u></>}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        <Button
                            onClick={() => {
                                const link = document.createElement("a");
                                link.download = `template.csv`;
                                link.href = "/template.csv";
                                link.click();
                            }}
                            color="primary"
                            style={{
                                color: colours.primary100,
                                textTransform: "none",
                                fontSize: 12,
                                fontFamily: "Poppins",
                            }}
                        >
                            Download template file here
                        </Button>
                    </div>
                </div>
                : null}

            {/* Upload Text*/}
            {selectedTab === "Upload Text" ?
                <div>
                    <div style={{ margin: 50 }}>
                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                // console.log(acceptedFiles);
                                let totalsize = acceptedFiles
                                    .map((z) => z.size)
                                    .reduce((a, b) => (a = a + b), 0);
                                setMaxFilesSizes(totalsize);
                                setFiles(acceptedFiles);
                            }}
                            accept="text/csv,text/txt,text/eml"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div
                                        {...getRootProps()}
                                        style={{
                                            background: "#F8F9FD",
                                            height: 117,
                                            boxShadow:
                                                "inset 4px 4px 5px #D1D9E6, inset -4px -4px 5px #FFFFFF",
                                            borderRadius: "20px",
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: 117,
                                                fontSize: 12,
                                                color: "#999DAC",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src={Group192}
                                                style={{ width: 96, height: 41 }}
                                            />
                                            <div>
                                                Drag & drop your text files (TXT, CSV or EML) here, or <u>click to browse</u>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                    {files.length === 0 ? null : (
                        <div style={{ marginLeft: 20, marginRight: 20, fontSize: 12 }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <img alt="" src={Group76} style={{ width: 26, height: 34 }} />
                                <div style={{ width: "100%", paddingLeft: 5 }}>
                                    {!uploading ? (
                                        <div>{"Files: " + files.length + ""}</div>
                                    ) : (
                                        <div>
                                            {"Uploading: " +
                                                ((totalLoaded / maxFilesSizes) * 100).toFixed(0) +
                                                "%"}
                                        </div>
                                    )}
                                    <Box sx={{ color: colours.primary100 }}>
                                        <LinearProgress
                                            variant="determinate"
                                            color="inherit"
                                            value={(totalLoaded / maxFilesSizes) * 100}
                                        />
                                    </Box>
                                </div>
                            </div>
                            {uploading ? (
                                <div style={{ fontSize: 8 }}>
                                    Wait for upload to complete before closing this page...
                                </div>
                            ) : null}
                        </div>
                    )}
                    <div style={{ marginLeft: 20 }}>
                        <div style={{ margin: 25 }}>
                            <Dropzone
                                onDrop={async (acceptedFiles) => {
                                    if(acceptedFiles.length === 0)
                                        Utils.sendSnackBar("Please select a single file");
                                    async function rdr(flname) {
                                        return new Promise((resolve, reject) => {
                                            var reader = new FileReader();
                                            reader.onload = function(e) {
                                                var contents = e.target.result;
                                                let list = contents
                                                    .split("\n")
                                                    .filter((z) => z !== "")
                                                    .map((z) => {
                                                        let line = z.split(",");
                                                        return {
                                                            filename: line[0].trim(),
                                                            agent: line[1].trim(),
                                                        };
                                                    });
                                                resolve(list);
                                            };
                                            reader.readAsText(flname);
                                        });
                                    }
                                    setAgents(await rdr(acceptedFiles[0]));
                                }}
                                accept="text/csv"
                                maxFiles={1}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div
                                            {...getRootProps()}
                                            style={{
                                                background: "#F8F9FD",
                                                height: 117,
                                                boxShadow:
                                                    "inset 4px 4px 5px #D1D9E6, inset -4px -4px 5px #FFFFFF",
                                                borderRadius: "20px",
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    height: 117,
                                                    fontSize: 12,
                                                    color: "#999DAC",
                                                    cursor: "pointer"

                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    src={Group195}
                                                    style={{ width: 96, height: 41 }}
                                                />
                                                <div>
                                                    {agents.length > 0
                                                        ? "DONE"
                                                        : <>Drag & drop your agent linking file here or <u>click to browse.</u></>}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        <Button
                            onClick={() => {
                                const link = document.createElement("a");
                                link.download = `template.csv`;
                                link.href = "/template.csv";
                                link.click();
                            }}
                            color="primary"
                            style={{
                                color: colours.primary100,
                                textTransform: "none",
                                fontSize: 12,
                                fontFamily: "Poppins",
                            }}
                        >
                            Download template file here
                        </Button>
                    </div>
                </div>
                : null}


            <Divider style={{ marginTop: 20 }} />

            {uploading === false ?
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 20,
                        justifyContent: "flex-end",
                    }}
                >

                    <Button
                        style={{
                            textTransform: "none",
                            float: "right",
                            marginRight: 5,
                            borderRadius: 20,
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            background: colours.white,
                            color: colours.primary100,
                            border: "1px solid #ddd",
                            boxShadow: "none",
                        }}
                        variant="contained"
                        onClick={() => closeDiag(null)}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            textTransform: "none",
                            float: "right",
                            borderRadius: 20,
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            background: colours.primary100,
                        }}
                        variant="contained"
                        onClick={async () => {
                            console.log("here");
                            setUploading(true);
                            let totals = [];
                            function total(file, load) {
                                let found = totals.find((z) => z.file === file);
                                if(found) found.load = load;
                                else {
                                    totals.push({ file, load });
                                    // console.log({ push: "herere", totals, newfile: file });
                                }
                                let sum = totals
                                    .map((z) => z.load)
                                    .reduce((a, b) => (a = a + b), 0);
                                if(sum > totalLoaded) setTotalLoaded(sum);
                                // console.log({ totals });
                            }
                            let folder = Utils.getUserGroup().filter((z) => z !== "admin")[0];

                            async function sendup(fl) {
                                // console.log({ fl });
                                let filename =
                                    Utils.create_UUID() + "." + fl.name.split(".").pop();
                                console.log({ filename });
                                let upf = await Storage.put(folder + "/" + filename, fl, {
                                    progressCallback(progress) {
                                        // console.log(progress);
                                        total(progress.key ? progress.key : "fl", progress.loaded);
                                        // setTotalLoaded(progress.loaded);
                                        // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                                    },
                                });

                                // let url = await Storage.get(folder + "/" + filename);
                                // console.log({ url });

                                // let jobid = await fetch(
                                //   "https://8aia1juk87.execute-api.eu-west-1.amazonaws.com/production/symbl/send",
                                //
                                //   {
                                //     method: "post",
                                //     body: JSON.stringify({ url: url }),
                                //     headers: {
                                //       "Content-Type": "application/json",
                                //       Accept: "application/json",
                                //     },
                                //   }
                                // );
                                // jobid = await jobid.json();
                                // console.log({ jobid });
                                let today = new Date(Date.now());
                                let sdate = today.toISOString().substr(0, 10);
                                let edate = today.toISOString().substr(0, 10);
                                if(datePick === "Yesterday") {
                                    today.setHours(today.getHours() - 24);
                                    sdate = today.toISOString().substr(0, 10);
                                    edate = today.toISOString().substr(0, 10);
                                } else if(datePick === "Date range") {
                                    sdate = startDate.toISOString().substr(0, 10);
                                    edate = endDate.toISOString().substr(0, 10);
                                }
                                let agent = "No agent";
                                let found = agents.find((z) => z.filename === fl.name);
                                if(found) agent = found.agent;
                                console.log({
                                    name: upf.key,
                                    agent: agent,
                                    file: fl.name,
                                    startdate: sdate,
                                    symblstatus: "uploaded",
                                    // symbljobid: jobid.jobId,
                                    // symblconversationid: jobid.conversationId,
                                    enddate: edate,
                                    campaignConversationsId: campaigndata.id,
                                    groups: campaigndata.groups,
                                });

                                let conv = await Utils.genericMutation("createConversation", {
                                    name: upf.key,
                                    agent: agent,
                                    file: fl.name,
                                    startdate: sdate,
                                    symblstatus: "uploaded",
                                    // symbljobid: jobid.jobId,
                                    // symblconversationid: jobid.conversationId,
                                    enddate: edate,
                                    campaignConversationsId: campaigndata.id,
                                    groups: campaigndata.groups,
                                });
                                fetch(
                                    "https://8aia1juk87.execute-api.eu-west-1.amazonaws.com/production/symbl/upload?id=" +
                                    conv.data.createConversation.id
                                );
                                return {
                                    originalname: fl.name,
                                    newname: upf.key,
                                    agent: agent,
                                    startdate: sdate,
                                    enddate: edate,
                                };
                            }
                            let r = await Promise.all(files.map(sendup));
                            setTotalLoaded(maxFilesSizes);
                            reset();
                            onClose(r);
                        }}
                    >Upload
                    </Button>
                </div>
                : null}

            {uploading === true ? <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 20,
                    justifyContent: "flex-end",
                }}>
                <div style={{
                    fontSize: 15,
                    width: "max-content",
                    fontWeight: "bold",
                    fontFamily: "Poppins"
                }}
                >Uploading...</div></div> : null}
        </Dialog>
    );
}

export default UploadDialogue;