import React from "react";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { colours ,TT} from "../consts.js";
import Utils from "../utils";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import FeatherIcon from "feather-icons-react";

function App() {
  const [data, setData] = React.useState([]);
  const [companyDT, setCompanyDT] = React.useState({});
  const [newCampDiag, setNewCampDiag] = React.useState(false);
  async function getCompanyData() {
    Utils.sendSnackBar("Please wait");
    let user = (await Utils.getUserGroupFromCognito()).filter(
      (z) => z !== "admin"
    );
    let company = await Utils.genericList("listCompanies", {
      filter: { internalname: { eq: user[0] } },
    });
     console.log({ user, company });
    let users = await Utils.GenAdmFunc("listUsersInGroup", {
      GroupName: user[0],
      UserPoolId: "STRING_VALUE",
      Limit: 60,
    });
    users = users.Users;
    async function getnames(input) {
      let res = await Utils.genericGet("getUser", input.Username);
      if (res ) {

        input.firstname = res.data.getUser.firstname;
        input.lastname = res.data.getUser.lastname;
      }
      return input;
    }
    let newusers = await Promise.all(users.map(getnames));
    setData(newusers);
    company = company.data.listCompanies.items[0].id;
    company = await Utils.genericGet("getCompany", company);
    company = company.data.getCompany;
    // company.trackers = JSON.parse(company.trackers);
    // company.campaigns.items.forEach(
    //   (z) => (z.trackers = JSON.parse(z.trackers))
    // );
      setCompanyDT(company);
      Utils.sendSnackBar("Done");
    // console.log({ user, company });
    // setData(company.alerts.items);
  }

  React.useEffect(() => {
    getCompanyData();
  }, []);

  async function inviteuser(email, company) {
    // console.log({ email, company });
    let usr1 = await Utils.GenAdmFunc("adminCreateUser", {
      UserPoolId: "STRING_VALUE",
      Username: email,
      DesiredDeliveryMediums: ["EMAIL"],
    });
    await Utils.GenAdmFunc("adminUpdateUserAttributes", {
     UserPoolId: "STRING_VALUE",
     Username: email,
     UserAttributes: [

       {
         Name: "email_verified",
         Value: "true",
       },
       // other user attributes like phone_number or email themselves, etc
     ],
   });
    // console.log({ usr1 });
    await Utils.genericMutation("createUser", {
      id: usr1.User.Username,
    });
    await Utils.GenAdmFunc("adminAddUserToGroup", {
      GroupName: company.internalname,
      Username: usr1.User.Username,
      UserPoolId: "STRING_VALUE",
    });

    getCompanyData();
    // console.log({ r });
  }

  return (
    <div className="vocalysed-home">
      <NewCampaignD1
        open={newCampDiag}
        onClose={(inp) => {
          if (inp === null) setNewCampDiag(false);
          else {
            // console.log({ inp });
            // inp.trackers = trackers;
            // setDiag2input(inp);
            setNewCampDiag(false);
            inviteuser(inp.email, companyDT);
            // if (inp !== null) setNewCampDiag2(true);
          }
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          background: "#f8f9fd",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: 30,
              marginTop: 10,
            }}
          ></div>
          <div  className="vocalysd-card vocalysd-table">
            <div style={{ alignItems: "end" }}>
              <Button
                style={{
                  textTransform: "none",
                  float: "right",
                  borderRadius: 20,
                  marginRight: 20,
                  marginTop: 10,
                  background: colours.primary100,fontFamily:"Poppins"
                }}
                variant="contained"
                onClick={() => setNewCampDiag(true)}
              >
                <FeatherIcon
                  icon="plus-circle"
                  style={{ color: colours.white, marginRight: 8 }}
                />
                Add user
              </Button>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Username
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Email
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Status
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Role
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((z, zidx) => (
                  <TableRow key={zidx} style={{}}>
                    <TableCell>
                      <div
                        style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                      >
                        {(z.firstname || "") + " " + (z.lastname || "")}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          fontFamily: "Poppins",
                        }}
                      >
                        {z.Attributes.find((a) => a.Name === "email").Value ||
                          ""}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Poppins",
                      }}
                    >
                      <Chip
                        label={z.Enabled ? "Active" : "Disabled"}
                        style={{
                          background: z.Enabled
                            ? colours.green100
                            : colours.critical100,
                          color: colours.white,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          fontFamily: "Poppins",
                        }}
                      >
                        Manager
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

function NewCampaignD1({ open, onClose }) {
  // let navigate = useNavigate();

  // const [value, setValue] = React.useState(null);
  // const [page, setPage] = React.useState(true);
  // const [companyName, setCompanyName] = React.useState("");
  // const [companyId, setCompanyId] = React.useState("");
  const [email, setEmail] = React.useState("");
  // const [type, setType] = React.useState("");
  // const [trackers, setTrackers] = React.useState([]);
  // const [agents, setAgents] = React.useState([]);
  // let today = new Date(Date.now()).toISOString().substr(0, 10);
  //
  // const [startDate, setStartDate] = React.useState(today);
  // const [endDate, setEndDate] = React.useState(today);

  // async function getCompanyName() {
  // let user = await Utils.getUserGroupFromCognito();
  // console.log({ user });
  //
  // console.log({ user });
  // let s = await Utils.genericGet("listCompanies", {
  //   filter: { internalname: { eq: "admin" } },
  // });
  // console.log({ s });
  // setCompanyId(companydata.id);
  // console.log({ s });
  // setCompanyName(companydata.name);
  // }

  // React.useEffect(() => getCompanyName(), [companydata]);

  return (
    <Dialog
      disableAutoFocus
      onClose={() => onClose(null)}
      onBackdropClick={() => {
        onClose(null);
      }}
      open={open}
      style={{
        padding: 0,

        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 30,
      }}
    >
      <DialogTitle style={{ padding: 0, minWidth: "600px", width: "600px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,

              fontFamily: "Poppins",
            }}
          >
            <div>
                Invite user
                <TT info={"When inviting a User, the system sends a one-page mailer with a link to the Sign Up page. Users can only be invited by an Admin."}/>
            </div>

            <FeatherIcon icon="x" style={{ color: "#ddd", cursor:"pointer" }}  onClick={()=>  onClose(null)}/>
          </div>
          <Divider style={{}} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            jus: "space-between",
            alignItems: "center",
            padding: 20,
            fontFamily: "Poppins",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
          <TextField
            id="outlined-basic"
            variant="outlined"
            style={{ width: "100%", marginTop: 20 }}
            label="Email address"
            InputLabelProps={{
                style: { color: '#84858a' },
            }}
            value={email}
            onChange={(c) => {
              setEmail(c.target.value);
            }}
          />
        </div>
        <Divider style={{}} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 20,
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              float: "right",
              marginRight: 5,
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.white,
              color: colours.primary100,
              border: "1px solid #ddd",
              boxShadow: "none",
            }}
            variant="contained"
            onClick={() => onClose(null)}
          >
            Cancel
          </Button>
          <Button
            style={{
              textTransform: "none",
              float: "right",
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Poppins",
              background: colours.primary100,
            }}
            variant="contained"
            onClick={() => {
              onClose({
                email,
              });
            }}
          >
            Invite
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
