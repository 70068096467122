import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { colours } from "../consts.js";
import { useNavigate } from "react-router-dom";
import Utils from "../utils";
import FeatherIcon from "feather-icons-react";

function App() {
    let navigate = useNavigate();
    const [data, setData] = React.useState([]);
    // const [companyDT, setCompanyDT] = React.useState({});
    async function getCompanyData() {
        Utils.sendSnackBar("Please wait");
        let user = (await Utils.getUserGroupFromCognito()).filter(
            (z) => z !== "admin"
        );
        let company = await Utils.genericList("listCompanies", {
            filter: { internalname: { eq: user[0] } },
        });
        company = company.data.listCompanies.items[0].id;
        company = await Utils.genericGetMOD("getCompany", company, {
            before: "alerts",
            after: "alerts(limit:10000)",
        });
        company = company.data.getCompany;
        company.trackers = JSON.parse(company.trackers);
        company.campaigns.items.forEach(
            (z) => (z.trackers = JSON.parse(z.trackers))
        );
        // setCompanyDT(company);
        // console.log({ user, company });
        setData(company.alerts.items);
        Utils.sendSnackBar("Done");;
    }

    React.useEffect(() => {
        getCompanyData();
    }, []);

    return (
        <div className="vocalysed-home">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    background: "#f8f9fd",
                    minHeight: "100vh",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginLeft: 30,
                            marginTop: 10,
                        }}
                    ></div>
                    <div className="vocalysd-card vocalysd-table">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Tracker name
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Agent
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Campaign
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Conversation
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                                            Date
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((z, zidx) => (
                                    <TableRow key={zidx} style={{}}>
                                        <TableCell>
                                            <div
                                                style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                                            >
                                                {z.name}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    fontFamily: "Poppins",
                                                }}
                                            >
                                                {z.agent}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontFamily: "Poppins",
                                            }}
                                        >
                                            {z.campaign}
                                        </TableCell>
                                        <TableCell>
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    fontFamily: "Poppins",
                                                }}
                                            >
                                                <div style={colours.tscriptEDicon2}>
                                                    <FeatherIcon
                                                        icon="play"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() =>
                                                            navigate("/conversation?id=" + z.conversation)
                                                        }
                                                    />
                                                </div>
                                                <span style={{ marginLeft: "8px" }}>{z.conversation}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div
                                                style={{
                                                    fontFamily: "Poppins",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between"
                                                }}
                                            >
                                                {z.createdAt.substr(0, 10)}
                                                <FeatherIcon
                                                    icon="trash"
                                                    style={{
                                                        cursor: "pointer",
                                                        color: colours.subduedgray,
                                                    }}
                                                    onClick={async () => {
                                                        Utils.sendSnackBar("Deleting alert...");
                                                        await Utils.genericMutation("deleteAlert", {
                                                            id: z.id,
                                                        });
                                                        getCompanyData();
                                                        Utils.sendSnackBar("Done.");
                                                    }}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
