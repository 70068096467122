import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { colours } from "../consts.js";
// import { useNavigate } from "react-router-dom";
import Utils from "../utils";

function App() {
  const [user, setUser] = React.useState({});
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  async function getCompanyData() {
    Utils.sendSnackBar("Please wait");
    let user = await Utils.getUserName();
    let userdetails = await Utils.genericGet("getUser", user.username);
    setUser(userdetails.data.getUser);
    setFirstname(userdetails.data.getUser.firstname);
    setLastname(userdetails.data.getUser.lastname);

    Utils.sendSnackBar(null);
  }

  React.useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          background: "#f8f9fd",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: 30,
              marginTop: 10,
            }}
          >
            Profile details
          </div>
          <div style={colours.vbox}>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="First name"
                InputLabelProps={{
                    style: { color: '#84858a' },
                }}
                style={{ marginRight: 16 }}
                value={firstname || ""}
                onChange={(c) => {
                  setFirstname(c.target.value);
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Last name"
                InputLabelProps={{
                    style: { color: '#84858a' },
                }}
                value={lastname || ""}
                onChange={(c) => {
                  setLastname(c.target.value);
                }}
              />
            </div>
            <div>
              <Button
                style={{
                  textTransform: "none",
                  float: "left",
                  marginTop: 16,
                  borderRadius: 20,
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  background: colours.primary100,
                  color: colours.white,
                  border: "1px solid #ddd",
                  boxShadow: "none",
                }}
                variant="contained"
                onClick={async () => {
                  Utils.sendSnackBar("Please wait");
                  await Utils.genericMutation("updateUser", {
                    id: user.id,
                    firstname: firstname,
                    lastname: lastname,
                  });
                  await Utils.refreshUserGroups()
                  Utils.sendSnackBar(null);
                }}
              >
                Save changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
